/* ======================== DIALOGS WITH HEADER ===================== */
.MuiDialog-paper {
	margin: 5px !important;
}

.MuiDialog-paperScrollPaper {
	height: auto !important;
}

.dialog-container {
	height: 545px;
	/* max-width: 440px; */
	overflow-y: hidden !important;
}

.dialog-container__header {
	width: 100%;
	box-sizing: border-box;
	position: sticky;
	top: 0;
	z-index: 999;
	text-align: center;
	background: var(--color-blue-younect);
}

.dialog-container__close {
	position: absolute;
	top: 2px;
	right: 5px;
	cursor: pointer;
}

.dialog-container__content {
	width: 530px;
	margin: 20px;
}

.dialog-container__footer {
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: space-between;
}
