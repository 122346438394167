/* ========================= BUTTONS WITH ICONS =========================== */
.btn__blue {
	width: 140px;
	margin: 0px 10px 5px 10px !important;
	padding: 5px 15px 5px 5px !important;
	display: flex;
	align-items: center;
	background: var(--color-blue-younect) !important;
	color: var(--color-white) !important;
}

.btn__blue--medium {
	width: 120px;
	margin: 0px 10px 5px 10px !important;
	padding: 5px 15px 5px 5px !important;
	display: flex;
	align-items: center;
	background: var(--color-blue-younect) !important;
	color: var(--color-white) !important;
}

.btn__blue:hover,
.btn__blue--medium:hover {
	background: var(--color-blue-dark) !important;
}

.btn__gray {
	width: 140px;
	margin: 0px 10px 5px 10px !important;
	padding: 5px 10px 5px 5px !important;
	display: flex;
	align-items: center;
	background: #cccccc !important;
	color: var(--color-white) !important;
}

.btn__gray--medium {
	width: 120px;
	margin: 0px 10px 5px 10px !important;
	padding: 5px 10px 5px 5px !important;
	display: flex;
	align-items: center;
	background: #cccccc !important;
	color: var(--color-white) !important;
}

.btn__gray:hover,
.btn__gray--medium:hover {
	background: #b8b8b8 !important;
}

.btn__green {
	width: 140px;
	margin: 0px 10px 5px 10px !important;
	padding: 5px 10px 5px 5px !important;
	display: flex;
	align-items: center;
	background: var(--color-green) !important;
	color: var(--color-white) !important;
}

.btn__green:hover {
	background: var(--color-green-dark) !important;
}

.btn__red {
	width: 140px;
	margin: 0px 10px 5px 10px !important;
	padding: 5px 10px 5px 5px !important;
	display: flex;
	align-items: center;
	background: var(--color-red) !important;
	color: var(--color-white) !important;
}

.btn__red:hover {
	background: var(--color-red-dark) !important;
}
