/* ======================== LOADING ===================== */
.loader {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	display: flex;
	z-index: 99999;
	overflow: hidden;
	background: rgba(0, 0, 0, 0.281);
}

.loading {
	margin: auto auto;
}
