/* ========================= ALERT DATE DIALOG =========================== */
.MuiPickersDay-daySelected {
	background: var(--color-blue-younect) !important;
}

/* ========================= ALERT TIME DIALOG =========================== */
.MuiPickersToolbar-toolbar,
.MuiPickersClockPointer-pointer,
.MuiPickersClock-pin,
.MuiPickersClockPointer-noPoint {
	background: var(--color-blue-younect) !important;
}

.MuiPickersClockPointer-thumb {
	border: 14px solid var(--color-blue-younect) !important;
	background: var(--color-blue-younect) !important;
}

.MuiButton-textPrimary {
	color: var(--color-blue-younect) !important;
}
