/* ======================== AVATAR UPLOAD ===================== */
.avatar-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.avatar-container__avatar {
	width: 100px !important;
	height: 100px !important;
	margin: -15px 0px 0px;
	border: 1px solid var(--color-blue-light);
}

.avatar-container__file {
	width: 100px;
	height: 100px;
	margin: -7px 0px 0px;
	border-radius: 50%;
	position: absolute;
	opacity: 0;
	cursor: pointer;
	outline: none;
}
