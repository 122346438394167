/* ========================= BACKGROUND COLORS ========================= */
.bg-blue {
	background: var(--color-blue-younect);
}

.bg-blue--light {
	background: var(--color-blue-light);
}

.bg-yellow {
	background: var(--color-yellow);
}

.bg-orange {
	background: var(--color-orange);
}

.bg-red {
	background: var(--color-red);
}

.bg-red--light {
	background: var(--color-red-light);
}

.bg-green {
	background: var(--color-green);
}

.bg-green--light {
	background: var(--color-green-light);
}

.bg-green--dark {
	background: var(--color-green-dark);
}

.bg-green--younect {
	background: var(--color-green-younect);
}

.bg-gray {
	background: var(--color-gray);
}

.bg-gray--light {
	background: var(--color-gray-light);
}

.bg-gray--younect {
	background: var(--color-gray-younect);
}

.bg-gray--bluish {
	background: var(--color-gray-bluish);
}

.bg-white {
	background: var(--color-white);
}

.bg-white--bluish {
	background: var(--color-white-bluish);
}

.bg-black {
	background: var(--color-black);
}
