/* ========================= ACTIVATION =========================== */
.activation {
  width: 100%;
	background: var(--color-blue-younect);
}

.activation__container {
	max-width: 470px;
	height: 100vh;
	margin: auto;
	display: flex;
	align-items: center;
}

.activation__inner {
	width: 100%;
	padding: 30px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	background: var(--color-gray-bluish);
}

@media (max-width: 599px) {
	.activation__inner {
		padding: 20px;
		background: var(--color-blue-younect);
	}
}

.activation__logo-container {
	width: 100px;
	height: 80px;
	margin: 0px auto 60px auto;
}

.activation__logo-negative {
	max-width: 100%;
	height: auto;
	display: block;
	color: var(--color-blue-younect) !important;
}

@media (max-width: 599px) {
	.activation__logo-negative {
		display: none;
	}

	.activation__logo {
		max-width: 100%;
		height: auto;
		display: block !important;
	}
}

.activation__text {
  border-radius: 5px 5px 0px 0px;
	text-align: center;
}

@media (max-width: 599px) {
	.activation__text {
		color: var(--color-white-bluish);
	}
}

.activation__text p:first-child {
  margin-bottom: 40px;
	font-size: 22px;
}

.activation__form {
  width: 100%;
  height: 180px;
  margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-around;
}

.activation__user {
	font-weight: bold;
  font-size: 22px;
  color: var(--color-blue-younect);
}

.activation__email {
	margin-left: 5px;
	font-weight: bold;
	color: var(--color-blue-younect);
}

@media (max-width: 599px) {
	.activation__user,
	.activation__email {
		color: var(--color-green-younect);
	}
}

.activation__btn {
	width: 100%;
	margin: 0px !important;
	border: 1px solid var(--color-white-bluish) !important;
	color: var(--color-white) !important;
}
