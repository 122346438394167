/* ========================= FORM =========================== */
.MuiFormLabel-root {
	padding: 0px 15px 0px 10px !important;
	font-size: 16px !important;
	color: var(--color-blue-younect) !important;
}

.MuiFormControlLabel-root {
	color: var(--color-blue-light) !important;
}

.MuiFormControlLabel-label {
	margin-left: -5px !important;
	font-size: 14px !important;
}

.Mui-error {
	color: #f44336 !important;
}
