/* ========================= TABLE / GRID =========================== */
.table-container {
	margin: 0px auto 25px auto;
	clear: both;
}

@media (max-width: 599px) {
	.table-container {
		width: 100%;
	}
}

.table-container__title-container {
	height: 35px;
	margin-bottom: -15px;
	display: flex;
}

.table-container__title {
	width: 55%;
	margin-top: 5px;
	padding-right: 40px;
	text-align: right;
	font-size: 18px;
	color: var(--color-blue-light);
}

@media (max-width: 599px) {
	.table-container__title {
		padding-left: 15px;
		text-align: left;
		font-size: 16px;
	}
}

.table-container__btn-container {
	width: 45%;
}

.table-container__btn {
	margin-top: 5px;
	padding: 0px 25px 0px 0px !important;
	border: none;
	float: right;
	display: flex;
	align-items: center;
	font-weight: 700 !important;
	font-size: 18px !important;
	text-transform: capitalize !important;
	text-decoration: none;
	cursor: pointer;
	background: transparent !important;
	color: var(--color-blue-light) !important;
}

@media (max-width: 959px) {
	.table-container__btn {
		padding-left: 0px;
		padding-right: 15px;
		font-size: 16px;
	}
}

.table-container__icon {
	margin-right: 5px;
	color: var(--color-green);
}

@media (max-width: 959px) {
	.table-container__icon {
		width: 25px;
		height: 25px;
	}
}

/* ========================= TABLE BODY =========================== */
.table-container__table {
	width: 100%;
}

.table-container__table--header {
	background: var(--color-gray-bluish);
}

.table-container__table--title {
	font-weight: 700 !important;
	font-size: 14px;
	font-family: "Montserrat", sans-serif !important;
	color: var(--color-blue-light) !important;
}

.table-container__table--show {
	font-size: 16px;
}

@media (max-width: 599px) {
	.table-container__table--show {
		max-width: 215px;
		font-size: 14px;
		word-wrap: break-word;
	}
}

.table-container__info {
	display: none;
}

@media (max-width: 599px) {
	.table-container__info {
		max-width: 230px;
		display: block;
	}
}

@media (max-width: 360px) {
	.table-container__info {
		max-width: 210px;
	}
}