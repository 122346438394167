/* ========================= ROOT =========================== */
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, /*"Segoe UI",*/ "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--color-gray-light);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* ======================== GENERAL DIALOG ACTIONS ===================== */
.MuiDialogActions-spacing {
	display: flex !important;
	justify-content: space-around !important;
}

/* ======================== GENERAL OUTLINE ELEMENTS ===================== */
.focus {
	outline: none;
}
