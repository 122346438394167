/* ========================= HORIZONTAL ALIGNMENTS ========================= */
.start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.space-around {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
