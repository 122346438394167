/* ======================== INPUTS ===================== */
.MuiInputBase-root {
	padding-left: 10px !important;
}

.input__primary {
	height: 48px;
	border-radius: 5px 5px 0px 0px;
	background: var(--color-white);
}

.input__secondary {
	height: 48px;
	border-radius: 5px 5px 0px 0px;
	background: var(--color-gray-bluish);
}

.font-input-white {
	width: 100%;
	padding-left: 30px !important;
	color: var(--color-white) !important;
}

.font-input-black {
	width: 100%;
	padding-left: 30px !important;
	color: #000 !important;
}
