/* ========================= HIDE ELEMENTS =========================== */
.hide {
	display: none !important;
}

@media (max-width: 1279px) {
	.hide-md {
		display: none !important;
	}
}

@media (max-width: 959px) {
	.hide-sm {
		display: none !important;
	}
}

@media (max-width: 599px) {
	.hide-xs {
		display: none !important;
	}
}
