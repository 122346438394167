.containerElevation {
	margin: -25px 20px 15px 20px !important;
	background: #f2f6f7;
	border: 1px solid #808080;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px;
	padding-bottom: 30px;
	position: relative;
}
.StyledMenuLink {
	text-decoration: none;
	font-size: 16px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.75);
}
.containerElevationPaddingLR {
	position: relative;
	width: 100%;
}
.collapse {
	width: 95%;
	padding-bottom: 30px;
}
.accordionTitle {
	font-family: "Roboto";
	font-weight: 500 !important;
	font-size: 18px;
	line-height: 21px;
	color: #4476ba;
	display: flex;
	align-items: center;
}

.accordionTitleIcon {
	transition: 0.5s linear;
}
.accordionTitleIconRotate {
	transform: rotate(180deg);
}
.accordionRoot {
	width: 100%;
	background: #f2f6f7 !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
}
.accordion {
	border-radius: 0 !important;
	background: #f2f6f7 !important;
}
.abc {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.aff {
	width: 100%;
	display: flex;
}
.buttonGroup {
	background: #fff;
	border-radius: 0px 5px 5px 0px;
	border: 1px solid #bdbdbd;
}
.buttons {
	padding: 1px !important;
	background: #fff !important;
}
.buttons.activeB {
	background: #f5f5f5 !important;
}
.bb {
	border-bottom: 1px solid;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.filterAdvance {
	display: flex;
}
.filterAdvanceInputs {
	width: 70%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(calc(200px), 1fr));
	grid-template-rows: repeat(3, 56px);

	grid-gap: 15px;
	padding-right: 30px;
	justify-self: right;
	justify-items: right;
	margin-right: 25px;
}
.filterAdvanceVariable {
	width: 20%;
	box-sizing: border-box;
}

.filterAdvanceSearch {
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 25px 0;
}

.input {
	width: 100%;
	background: #fff;
}
.teste {
	flex-wrap: nowrap !important;
	align-items: flex-end;
	display: flex;
}

@media (max-width: 425px) {
	.containerElevation {
		margin: -25px 5px 15px 5px !important;
		padding: 30px;
		padding-bottom: 30px;
	}
	.filterAdvanceInputs {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(calc(200px), 1fr));
		grid-template-rows: repeat(3, 56px);

		grid-gap: 15px;
		padding-right: 30px;
		justify-self: right;
		justify-items: right;
		margin-right: 25px;
	}
	.filterAdvance {
		display: flex;
		flex-direction: column !important;
	}
}
