/* ========================= GHOST BUTTONS =========================== */
.btn__blue--outlined {
	margin: 0px 10px 5px 10px !important;
	border: 1px solid var(--color-blue-younect) !important;
	display: flex;
	align-items: center;
	background: transparent !important;
	color: var(--color-blue-younect) !important;
}

.btn__blue--outlined:hover {
	background: var(--color-blue-younect) !important;
	color: var(--color-white) !important;
}

.btn__green--outlined {
	margin: 0px 10px 5px 10px !important;
	border: 1px solid var(--color-green) !important;
	display: flex;
	align-items: center;
	background: transparent !important;
	color: var(--color-green) !important;
}

.btn__green--outlined:hover {
	background: var(--color-green) !important;
	color: var(--color-white) !important;
}

.btn__red--outlined {
	margin: 0px 10px 5px 10px !important;
	border: 1px solid var(--color-red) !important;
	display: flex;
	align-items: center;
	background: transparent !important;
	color: var(--color-red) !important;
}

.btn__red--outlined:hover {
	background: var(--color-red) !important;
	color: var(--color-white) !important;
}
