/* ========================= VARIABLES CSS =========================== */
:root {
	--color-blue-younect: #0548a3;
	--color-blue-light: #4476ba;
	--color-blue-dark: #003d77;
	--color-blue-greyish: #6b7c93;
	--color-yellow: #ffc866;
	--color-orange: #ff9800;
	--color-red: #f50057;
	--color-red-light: #e93f66;
	--color-red-dark: #dc004e;
	--color-green: #469c47;
	--color-green-light: #15ce9a;
	--color-green-dark: #3f8c40;
	--color-green-younect: #9cC236;
	--color-gray: #c4c4c4;
	--color-gray-light: #e8e8e8;
	--color-gray-younect: #9d9c9c;
	--color-gray-bluish: #f2f6f7;
	--color-white: #fff;
	--color-white-bluish: #d1d5ed;
	--color-black: #37383c;

	--font-secondary: "Montserrat", sans-serif;
	--bold: bold;
}