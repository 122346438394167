/* ======================== HEADER BOTTOM ===================== */
.header-bottom {
	height: 120px;
	padding-top: 0px;
	border-top: 1px solid var(--color-blue-light);
	display: flex;
	justify-content: space-between;
	background: var(--color-blue-younect);
}

.header__title {
	margin-top: -20px;
	margin-left: 50px;
	margin-bottom: 10px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
	letter-spacing: 1px;
	color: var(--color-white-bluish);
	color: var(--color-gray-light);
}

@media (max-width: 959px) {
	.header__title {
		margin-left: 20px !important;
		font-size: 20px;
	}
}

@media (max-width: 599px) {
	.header__title {
		margin-left: 5px !important;
	}
}

@media (max-width: 360px) {
	.header__title {
		margin-left: 0px !important;
	}
}

.header__search {
	width: 350px;
	margin-left: 50px;
	padding-left: 10px;
	border-radius: 5px;
	position: relative;
	background: #2a63b1;
	color: var(--color-white);
}

@media (max-width: 959px) {
	.header__search {
		margin-left: 20px !important;
	}
}

@media (max-width: 599px) {
	.header__search {
		max-width: 150px;
		margin-left: 5px !important;
	}
}

@media (max-width: 360px) {
	.header__search {
		margin-left: 0px !important;
		margin-right: 5px;
	}
}

.header__search:hover {
	background-color: var(--color-blue-light);
}

.header__icon {
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-gray-light);
}

.header__buttons-container {
	margin-right: 30px;
}

@media (max-width: 959px) {
	.header__buttons-container {
		margin-right: 0px;
		padding: 0px;
	}
}

.header__wrapper {
	margin-right: 10px;
	display: flex;
	align-items: flex-end;
}

@media (max-width: 959px) {
	.header__wrapper {
		margin-top: -10px;
	}
}

@media (max-width: 599px) {
	.header__wrapper {
		margin-top: -10px;
		margin-right: 0px;
		margin-left: -38px;
	}
}

@media (max-width: 360px) {
	.header__wrapper {
		margin-left: -42px;
	}
}

.header__btn-plus {
	height: 30px;
	margin: 15px 0px 0px 0px !important;
	padding: 5px 25px 0px 20px;
	border-right: 1px solid var(--color-blue-light) !important;
	border-radius: 0px !important;
	float: right;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 16px !important;
	text-transform: capitalize !important;
	background: transparent;
	color: var(--color-gray-light) !important;
}

@media (max-width: 959px) {
	.header__btn-plus {
		padding-left: 0px;
		padding-right: 15px;
		font-size: 14px;
	}
}

.header__btn-options {
	height: 30px;
	margin: 10px 0px 0px 0px !important;
	padding: 5px 20px 0px 20px;
	float: right;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 16px !important;
	text-transform: capitalize !important;
	background: transparent;
	color: var(--color-gray-light) !important;
}

@media (max-width: 959px) {
	.header__btn-options {
		padding-left: 13px;
		padding-right: 0px;
		font-size: 14px;
	}
}
