/* ========================= COLORS ========================= */
.blue {
	color: var(--color-blue-younect) !important;
}

.blue--light {
	color: var(--color-blue-light)!important;
}

.yellow {
	color: var(--color-yellow);
}

.orange {
	color: var(--color-orange);
}

.red {
	color: var(--color-red) !important;
}

.red--light {
	color: var(--color-red-light);
}

.green {
	color: var(--color-green) !important;
}

.green--light {
	color: var(--color-green-light);
}

.green--dark {
	color: var(--color-green-dark);
}

.green--younect {
	color: var(--color-green-younect);
}

.gray {
	color: var(--color-gray);
}

.gray--light {
	color: var(--color-gray-light);
}

.gray--younect {
	color: var(--color-gray-younect);
}

.gray--bluish {
	color: var(--color-gray-bluish);
}

.white {
	color: var(--color-white);
}

.white--bluish {
	color: var(--color-white-bluish);
}

.black {
	color: var(--color-black)!important;
}
