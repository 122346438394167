/* ========================= SCROLL BAR =========================== */
* {
	scrollbar-width: thin;
	scrollbar-color: var(--color-gray-light);
}
*::-webkit-scrollbar {
	width: 8px;
}
*::-webkit-scrollbar-track {
	background: var(--color-gray-bluish);
}
*::-webkit-scrollbar-thumb {
	border-radius: 20px;
	background: var(--color-gray);
}
