/* ========================= HEADINGS ========================= */
.MuiTypography-h5 {
	font-weight: 500 !important;
	font-size: 22px !important;
	font-family: "Montserrat", sans-serif !important;
}

.MuiTypography-h6 {
	font-size: 24px !important;
}

/* ======================== FONT FAMILY SECONDARY ===================== */
.font-secondary {
	font-family: var(--font-secondary);
}

/* ======================== FONT WEIGHT ===================== */
.bold {
	font-weight: var(--bold);
}
