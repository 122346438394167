/* ========================= EDIT ICON =========================== */
.icon__edit {
	margin-top: -4px;
	color: #bdbdbd;
}

.icon__edit:hover {
	color: var(--color-yellow);
}

@media (max-width: 959px) {
	.icon__edit {
		display: none;
	}
}

/* ========================= DELETE ICON =========================== */
.icon__delete {
	margin-top: -4px;
	color: #bdbdbd;
}

.icon__delete:hover {
	color: var(--color-red-light);
}

/* ========================= PLUS ICON =========================== */
.icon__plus {
	margin-right: 5px;
	color: #93fb3d;
}

@media (max-width: 959px) {
	.icon__plus {
		width: 25px;
		height: 25px;
	}
}

/* ========================= OPTIONS ICON =========================== */
.icon__options {
	margin-right: 5px;
	color: #4fc3f7;
}

@media (max-width: 959px) {
	.icon__options {
		width: 25px;
		height: 25px;
	}
}

/* ========================= HELP ICON =========================== */
.icon__help {
	width: 30px !important;
	height: 30px !important;
	margin-right: -25px;
	color: var(--color-gray-light);
}

@media (max-width: 959px) {
	.icon__help {
		width: 25px;
		height: 25px;
	}
}

/* ========================= SHARE ICON =========================== */
.icon__share {
	margin-left: 5px;
	color: var(--color-white);
}

/* ========================= SAVE ICON =========================== */
.icon__save {
	margin-left: 5px;
	color: var(--color-white);
}

/* ========================= CLIPS ICON =========================== */
.icon__clips {
	margin-left: 5px;
	color: var(--color-white);
}

/* ========================= ARROW BACK ICON =========================== */
.icon__back {
	margin-left: 5px;
	color: var(--color-white-bluish);
}

/* ========================= ARROW NEXT ICON =========================== */
.icon__next {
	color: var(--color-white);
}

/* ========================= ARROW DOWN ICON =========================== */
.icon__down {
	color: #707070;
}

/* ========================= APPS ICON =========================== */
.icon__apps {
	color: #3f51b5;
}

/* ========================= LOCATION ICON =========================== */
.icon__location {
	color: #3f51b5;
}

/* ========================= ERROR ICON =========================== */
.icon__error {
	color: var(--color-yellow);
}

/* ========================= CHECK ICON =========================== */
.icon__check {
	color: var(--color-white);
}

/* ========================= CLOSE ICON =========================== */
.icon__close {
	color: var(--color-gray-light);
}

/* ========================= DOLLAR ICON =========================== */
.icon__dollar {
	color: #b5bce2;
}

/* ========================= VISIBILITY ICON =========================== */
.icon__visibility {
	color: #bdbdbd;
}
